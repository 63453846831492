import React from 'react';

import { getUserLocalInfo } from '@onesy/utils';
import { Line } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';
import { UrlShortener } from '@onesy/api';

import { ReactComponent as Logo } from '../assets/svg/logo.svg';

import { Meta } from 'ui';
import { LogService, UrlShortenerService } from 'services';

const useStyle = style(theme => ({
  root: {
    height: theme['100vh']
  },

  logo: {
    height: 57,
    width: 'auto'
  }
}), { name: 'onesy-public-URL' });

const Element = () => {
  const { classes } = useStyle();

  const theme = useOnesyTheme();

  const onAnalytic = React.useCallback(async () => {
    const body: any = {
      location: await getUserLocalInfo()
    };

    const url = window.location.pathname.split('/').filter(Boolean)[1];

    if (url) {
      body.url = url;

      const result = await UrlShortenerService.analytic(body);

      if (result.status >= 400) {
        LogService.important(`URL shortener analytic error`, result.response);
      }
      else {
        const response = result.response.response;

        const url = response?.to?.url;

        if (url) window.location.replace(url);
      }
    }
  }, []);

  const init = React.useCallback(async () => {
    // analytic
    onAnalytic();

    // redirect
    const urlShortener = (window as any).urlShortener as UrlShortener;

    if (urlShortener) {
      window.location.replace(urlShortener.to.url!);
    }
  }, [onAnalytic]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  return <>
    <Meta>
      <title>onesy URL</title>
    </Meta>

    <Line
      gap={0}

      direction='column'

      align='center'

      justify='center'

      flex

      fullWidth

      className={classNames([
        'onesy-page-URL',

        classes.root
      ])}
    >
      <Logo
        className={classNames([
          classes.logo,
          classes[`logo_${theme.palette.light ? 'light' : 'dark'}`]
        ])}
      />
    </Line>
  </>;
};

export default Element;
